/** @format */

import { RoadFlexFleetCustomers } from "@roadflex/types";
import classNames from "classnames";
import Image from "next/image";
import { useEffect, useRef, useState } from "react";
import { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";
import { Swiper, SwiperSlide } from "swiper/react";

type RoadFlexCustomersProps = {
  fleetCompanies: RoadFlexFleetCustomers[];
  fleetUpColorScheme?: boolean;
  promilesPartnership?: boolean;
};

export default function RoadFlexCustomers(props: RoadFlexCustomersProps) {
  const {
    fleetCompanies,
    fleetUpColorScheme = false,
    promilesPartnership = false,
  } = props;

  const sectionRef = useRef<HTMLDivElement | null>(null);
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (!sectionRef.current) return;

      const topPosition = sectionRef.current.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      if (topPosition < windowHeight * 0.9) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <div className="container w-full text-black py-14 md:py-32 2xl:max-w-[1750px!important]">
      <div className="mb-12 text-center md:mb-24">
        {!fleetUpColorScheme && (
          <div
            className={`transition-all duration-700 ease-in-out transform text-xl font-bold text-brown-800 2xl:text-3xl ${
              isVisible
                ? "translate-y-0 opacity-100"
                : "-translate-y-40 opacity-0"
            }`}
            ref={sectionRef}
          >
            <div className="mb-8">
              TRUSTED BY THOUSANDS OF FLEETS NATIONWIDE
            </div>
            {!promilesPartnership && (
              <div className="text-lg font-normal 2xl:text-2xl">
                The Most Successful Fleets choose RoadFlex
              </div>
            )}
            {promilesPartnership && (
              <div className="text-lg font-normal 2xl:text-2xl">
                The Most Successful Fleets choose ProMiles + RoadFlex
              </div>
            )}
          </div>
        )}
        {fleetUpColorScheme && (
          <div
            className={`transition-all duration-700 ease-in-out transform text-xl font-bold text-fleetupBlue 2xl:text-3xl ${
              isVisible
                ? "translate-y-0 opacity-100"
                : "-translate-y-40 opacity-0"
            }`}
            ref={sectionRef}
          >
            <div className="mb-8">
              TRUSTED BY THOUSANDS OF FLEETS NATIONWIDE
            </div>
            <div className="text-lg font-normal 2xl:text-2xl">
              The Most Successful Fleets choose RoadFlex
            </div>
          </div>
        )}
      </div>
      <Swiper
        modules={[Autoplay]}
        speed={4000}
        breakpoints={{
          998: { slidesPerView: 6, slidesPerGroup: 1, spaceBetween: 20 },
          625: { slidesPerView: 4, slidesPerGroup: 1, spaceBetween: 12 },
          425: { slidesPerView: 3.5, slidesPerGroup: 1, spaceBetween: 12 },
          0: { slidesPerView: 2, slidesPerGroup: 1, spaceBetween: 12 },
        }}
        autoplay={{ delay: 0, stopOnLastSlide: false }}
        lazyPreloadPrevNext={3}
        loop={true}
        className="child:!ease-linear "
        wrapperClass="flex flex-row items-center"
      >
        {fleetCompanies.length > 0 &&
          //concating the same array because to create infinite loop slides perView should not be more than half of slides. Currently the set value is 6 which is more than 4.5, so adding smae elements in the array to make the slide count double
          //ref link:https://stackoverflow.com/questions/75450242/swiper-js-loop-when-slidesperview-is-bigger-than-half-of-the-amount-of-slides
          fleetCompanies
            .concat(fleetCompanies)
            .map((fleetCompany, i: number) => (
              <SwiperSlide key={i} className="">
                <div
                  className={classNames(
                    `relative flex justify-center max-w-[250px] max-h-[155px]`,
                  )}
                >
                  <Image
                    src={fleetCompany.imageLink}
                    // layout="responsive"
                    loading="eager"
                    height={fleetCompany.height}
                    width={fleetCompany.width}
                    alt="Fleets RoadFlex is serving"
                  />
                </div>
              </SwiperSlide>
            ))}
      </Swiper>
    </div>
  );
}
