/** @format */

import { ArrowDownIcon, ArrowRightCircleIcon } from "@heroicons/react/24/solid";
import {
  Button,
  ButtonSize,
  ButtonVariant,
} from "@roadflex/react-components-www";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";

interface Panel4Props {
  showSavingsButton?: boolean;
}

export default function Panel4({ showSavingsButton = true }: Panel4Props) {
  const router = useRouter();
  const redirectTo = (page) => {
    router.push({
      pathname: `/${page}`,
    });
  };
  const sectionRef = useRef<HTMLDivElement | null>(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (!sectionRef.current) return;

      const topPosition = sectionRef.current.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      if (topPosition < windowHeight * 0.9) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const scorecards = [
    {
      id: 1,
      stats: "11%",
      statsDescription:
        "average fuel bill savings when switching to RoadFlex, often hundreds of thousands of dollars",
    },
    {
      id: 2,
      stats: "",
      statsDescription: "",
    },
    {
      id: 3,
      stats: "6 hours",
      statsDescription:
        "average time saved each week on admin and finance tasks",
    },
    {
      id: 4,
      stats: "",
      statsDescription: "",
    },
    {
      id: 5,
      stats: "3 hours",
      statsDescription:
        "average time saved each week on fleet reports and analysis",
    },
  ];

  return (
    <div
      id="panel-4"
      className="flex items-center justify-center w-full bg-cover bg-banner-header"
    >
      <div
        ref={sectionRef}
        className={`transition-all duration-700 ease-in-out transform container w-full space-y-14 md:space-y-24 my-14 md:my-32 ${
          isVisible ? "translate-x-0 opacity-100" : "-translate-x-40 opacity-0"
        }`}
      >
        <div className="text-white ">
          <div className="mb-8 text-3xl font-bold text-center md:text-4xl 3xl:text-5xl">
            Thousands of Fleets Have Switched To RoadFlex
          </div>
          <div className="text-lg text-center 2xl:text-2xl">
            Next-Generation Platform to automate and centralize all your
            fueling. Eliminate fuel fraud and theft.
          </div>
        </div>
        <div className="flex flex-col items-stretch justify-between w-full h-full space-y-8 text-white lg:space-y-0 lg:flex-row">
          {scorecards.length > 0 &&
            scorecards.map((scorecard, idx: number) => (
              <div key={idx}>
                {idx % 2 === 0 && (
                  <div
                    key={idx}
                    ref={sectionRef}
                    className={`transition-all duration-700 ease-in-out transform flex flex-col items-center h-auto text-center w-full px-8 ${
                      isVisible
                        ? "translate-x-0 opacity-100"
                        : "-translate-x-40 opacity-0"
                    }`}
                  >
                    <div className="max-w-[230px] flex flex-col items-center">
                      <div className="">
                        <div className="flex flex-row items-center pb-4 text-3xl font-semibold text-orange-500 md:text-5xl">
                          <ArrowDownIcon className="w-10 h-10 text-orange-500"></ArrowDownIcon>{" "}
                          {scorecard.stats}
                        </div>
                      </div>
                      <div className="font-semibold">
                        {scorecard.statsDescription}
                      </div>
                    </div>
                  </div>
                )}
                {idx % 2 === 1 && (
                  <div
                    key={idx}
                    ref={sectionRef}
                    className={`hidden lg:block transition-all duration-700 ease-in-out w-1 border-0 transform bg-gradient-to-b from-orange-300 via-orange-700 to-orange-300 opacity-40 ${
                      isVisible
                        ? "translate-x-0 opacity-100"
                        : "-translate-x-40 opacity-0"
                    }`}
                  ></div>
                )}
              </div>
            ))}
        </div>
        {showSavingsButton && (
          <Button
            variant={ButtonVariant.Orange}
            size={ButtonSize.Normal}
            className="py-4 mx-auto font-semibold"
            onClick={() => redirectTo("savings")}
          >
            SAVINGS CALCULATOR
            <ArrowRightCircleIcon className="w-6 h-6 ml-3"></ArrowRightCircleIcon>
          </Button>
        )}
      </div>
    </div>
  );
}
