/** @format */

import getConfig from "next/config";
import Image from "next/image";

const { publicRuntimeConfig } = getConfig();
const { NEXT_PUBLIC_CDN_URL } = publicRuntimeConfig;

type Panel3Props = {
  title?: string;
  subtitle?: string;
};

export default function Panel3({ title, subtitle }: Panel3Props) {
  return (
    <div className="container flex flex-col items-center w-full text-brown-900 mb-14 md:mb-28">
      {title && (
        <div className="mb-8 text-3xl font-bold text-center md:text-4xl 3xl:text-5xl">
          {title}
        </div>
      )}
      {subtitle && (
        <div className="mb-8 text-lg font-medium text-center 3xl:text-xl">
          {subtitle}
        </div>
      )}
      <div className="flex items-center justify-center w-full gap-10 mt-4 md:mt-0">
        <div className="relative w-full max-w-[400px] md:max-w-[90%] h-full">
          {" "}
          <Image
            layout="responsive"
            width={"750"}
            height={"500"}
            loading="eager"
            className=""
            src={`${NEXT_PUBLIC_CDN_URL}/landing_page/images/roadflex-dashboard-risk-new.png`}
            alt="Roadflex dashboard"
          ></Image>
        </div>
      </div>
    </div>
  );
}
